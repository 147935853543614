import {put, take, takeEvery, select} from '@redux-saga/core/effects';
import {loadCollectionQueryLimitToOneFirestore} from '../services/firebase/firestore';
import { processAuthConditions } from './utils/utils';

const getAuth = (state) => state.auth;

export const operationLoadLimitToOne = {
  name: 'LOAD_LIMIT_TO_ONE',
  reducer: {
    initialState: {loading: false, loaded: false, loadFailed: false},
    cases: [
      {
        name: 'LOAD_LIMIT_TO_ONE_',
        newState: {loading: true, loaded: false, loadFailed: false},
      },
      {
        name: 'LOADED_LIMIT_TO_ONE_',
        newState: {loading: false, loaded: true, loadFailed: false},
      },
      {
        name: 'LOAD_LIMIT_TO_ONE_FAILED_',
        newState: {loading: false, loaded: false, loadFailed: true},
      },
    ],
  },
  action: {
    function: (name) => {
      return {
        loadLimitToOne: (payload) => {
          return {type: 'LOAD_LIMIT_TO_ONE_' + name, payload: payload};
        },
        loadedLimitToOne: (payload) => {
          return {type: 'LOADED_LIMIT_TO_ONE_' + name, payload: payload};
        },
        loadFailedLimitToOne: () => {
          return {type: 'LOAD_LIMIT_TO_ONE_FAILED_' + name};
        },
        stopLoadLimitToOne: () => {
          return {type: 'STOP_LIMIT_TO_ONE_LOAD_' + name};
        },
      };
    },
  },
  sagas: {
    sagasActionName: 'LOAD_LIMIT_TO_ONE_',
    function: (name) => {
      return function* loadLimitToOne({payload}) {
        try {
          yield put({type: 'LOAD_ALERT'});
          const {path, idName, conditions, field, order} = payload;
          const auth = yield select(getAuth);
          const conditionsBase = conditions.concat([]);
          processAuthConditions(auth, conditionsBase);
          const Channel = loadCollectionQueryLimitToOneFirestore(
            path,
            idName,
            conditionsBase,
            field,
            order,
          );
          yield takeEvery(Channel, function* (action) {
            yield put({
              type: 'LOADED_LIMIT_TO_ONE_' + name,
              payload: {dataLoad: {list: action.data}},
            });
            yield put({
              type: 'LOADED_ALERT',
            });
          });
          yield take([
            'LOAD_LIMIT_TO_ONE_' + name,
            'STOP_LOAD_LIMIT_TO_ONE_' + name,
            'STOP_LOAD_EVERYTHING',
          ]);
          Channel.close();
          yield put({
            type: 'LOADED_LIMIT_TO_ONE_' + name,
            payload: {dataLoad: {list: []}},
          });
        } catch (e) {
          yield put({
            type: 'LOADED_LIMIT_TO_ONE_' + name,
            payload: {dataLoad: {list: []}},
          });
          yield put({
            type: 'LOADED_ALERT',
          });
          yield put({type: 'LOADED_FAILED'});
        }
      };
    },
  },
};
